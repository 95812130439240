<template>
    <div>
        <div align="center">
            <br>
            <span class="text-h4">Richiesta di quotazione in Compagnia</span>
        </div>
        <br><br>
        <div class="row justify-center">
            <div class="col-12 col-md-8" align="justify">
                Ciao {{operatore}},<br><br>
                I dati inseriti nel questionario di Analisi del Rischio non hanno restituito
                proposte che rispondono ai parametri richiesti. Bisogna pertanto procedere con
                una emissione manuale della pratica. Questa sezione è in fase di sviluppo e
                ti chiediamo quindi di inviare una email ai responsabili della sezione Rami
                per fare una richiesta di quotazione in Compagnia.
                Stiamo lavorando per automatizzare questa sezione del programma che sarà disponibile
                quanto prima.
                <br><br>
                Grazie
            </div>
            <div class="col-12 col-md-8" align="right">
                Il Team di sviluppo
            </div>
        </div>

        <br><br><hr>

        <div class="col-12 col-md-6" align="center">
            <QQButton label="FINE" color="blue-grey" icon="mdi-check-outline" size="md"
                @click.native="onNuovoClicked"
            />
        </div>

    </div>

</template>

<script>
    import { mapState } from "vuex";
    import { mapGetters } from "vuex";
    import QQButton from "@/components/QQButton.vue";

    export default {
        name: "FineAcquisto",
        data() {
            return {

            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapGetters([
                "jwtPayload"
            ]),
            operatore() { return this.jwtPayload.operatore},
            ...mapState({
                formPreventivo: state => state.formPreventivo
            }),
            NumeroPreventivo() {
                return this.formPreventivo.preventivo.id;
            },
            DataCreazione() {
                return this.formPreventivo.preventivo.create_at;
            }
        },
        methods: {
            onNuovoClicked() {
                window.location = "/";
            }
        }
    }
</script>

<style scoped>
    td.risposta {
        border-bottom: 1px SOLID #808080;
        padding: 6px;
    }
</style>
